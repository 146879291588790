<template>
  <div class="block_section_title">
    <slot />
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.block_section_title {
  font-size: 12px;
  color: #ffffff;
  line-height: 1;
  font-weight: bold;
  padding: 10px;
  background: rgba(21, 55, 135, 0.8);
}
</style>
